import React, { useState } from "react";

import { Queries } from "@api/graphql/queries/Queries";
import { AnyProduct, homePage, Publication, Slider } from "@api/graphql/types";
import { ssrRequest } from "@api/GraphQLClient";
import { ProductCard, ProductCardGrid, PublicationCard } from "@components/Card/ProductCard";
import { WideProductCard } from "@components/Card/WideProductCard";
import CartProductAddedModal from "@components/Cart/CartProductAddedModal";
import { HomePageCarousel } from "@components/Home/HomeCarousel";
import { Section } from "@components/Section";
import { CACHE_TIMEOUTS } from "@utils/Constants";
import { Path } from "@utils/Path";
import { NormalProduct } from "@api/graphql/typeHelpers";
import { Meta } from "@components/Meta";
import { useLoctool } from "@bigfish/react-loctool";

interface InitialProps {
    highlightedPublications: { name: string; publications: Publication[] };
    highlightedProducts: { name: string; products: AnyProduct[] };
    printedPublications: { name: string; publications: Publication[] };
    digitalProducts: { name: string; products: AnyProduct[] };
    sliders: Slider[];
}

type Props = InitialProps;

const HomePage = (props: Props) => {
    const Intl = useLoctool();

    const { highlightedPublications, highlightedProducts, printedPublications, digitalProducts, sliders } = props;
    const [productAddedToCart, setProductAddedToCart] = useState<AnyProduct | null>(null);

    const onCartQuantityChange = (product: AnyProduct, previousQuantity: number, newQuantity: number) => {
        if (previousQuantity === 0 && newQuantity === 1) {
            setProductAddedToCart(product);
        }
    };

    const closeModal = () => {
        setProductAddedToCart(null);

        document.body.style.overflowY = "auto";
    };

    // const originalRecommendedProucts = useSelector((state: ApplicationState) => state.cart);
    // const [recommendedProducts, setRecommendedProducts] = useState<AnyProduct[]>([]);
    // useEffect(() => {
    //     setRecommendedProducts(originalRecommendedProucts.recommendedProducts);
    // }, []);

    return (
        <>
            <Meta />
            <h1 aria-label="Főoldal" className="show-for-sr"></h1>
            {sliders && <HomePageCarousel sliders={sliders} />}
            <div className="_ce_measure_column">
                <div
                    className="_ce_measure_widget _ce_measure_appear"
                    data-ce-measure-widget="kiemelt-elofizetesi-ajanlataink"
                    data-ce-measure-appear="kiemelt-elofizetesi-ajanlataink"
                >
                    <Section
                        title={highlightedPublications.name}
                        background="beige"
                        linkTitle={Intl.formatMessage({ id: "pages.home.highlightedPublicationsLink" })}
                        linkHref={Path.currentOffers}
                        className="_ce_measure_row"
                    >
                        <ProductCardGrid>
                            {highlightedPublications.publications.map(publication => {
                                return <PublicationCard key={publication.id} publication={publication} />;
                            })}
                            {highlightedProducts.products.map(product => (
                                <WideProductCard key={product.id} product={product as NormalProduct} onCartQuantityChange={onCartQuantityChange} />
                            ))}
                        </ProductCardGrid>
                    </Section>
                </div>
            </div>
            <div className="_ce_measure_column">
                <div
                    className="_ce_measure_widget _ce_measure_appear"
                    data-ce-measure-widget="nyomtatott-elofizetesek"
                    data-ce-measure-appear="nyomtatott-elofizetesek"
                >
                    <Section
                        title={printedPublications.name}
                        background="white"
                        linkTitle={Intl.formatMessage({ id: "pages.home.printedPublicationsLink" })}
                        linkHref={Path.magazines}
                        className="_ce_measure_row"
                    >
                        <ProductCardGrid>
                            {printedPublications.publications.map(publication => {
                                return <PublicationCard key={publication.id} publication={publication} />;
                            })}
                        </ProductCardGrid>
                    </Section>
                </div>
            </div>
            <div className="_ce_measure_column _ce_measure_appear">
                <div className="_ce_measure_widget" data-ce-measure-widget="digitalis-elofizetesek" data-ce-measure-appear="digitalis-elofizetesek">
                    <Section
                        title={digitalProducts.name}
                        background="beige"
                        linkTitle={Intl.formatMessage({ id: "pages.home.digitalPublicationsLink" })}
                        linkHref={Path.digitalMagazines}
                        className="_ce_measure_row"
                    >
                        <ProductCardGrid>
                            {digitalProducts.products.map(product => {
                                return <ProductCard key={product.id} product={product as NormalProduct} onCartQuantityChange={onCartQuantityChange} />;
                            })}
                        </ProductCardGrid>
                    </Section>
                </div>
            </div>
            {/* recommendedProducts.length > 0 && <RecommendedProducts products={recommendedProducts} /> */}
            {productAddedToCart && <CartProductAddedModal mounted={true} product={productAddedToCart as NormalProduct} onClose={() => closeModal()} />}
        </>
    );
};

export async function getServerSideProps() {
    const { data } = await ssrRequest<homePage>(
        {
            query: Queries.homePage,
        },
        { cacheTimeout: CACHE_TIMEOUTS.homepageData },
    );
    if (data) {
        return {
            props: {
                highlightedPublications: data.highlightedPublications,
                highlightedProducts: data.highlightedProducts,
                printedPublications: data.printedPublictaions,
                digitalProducts: data.digitalProducts,
                sliders: data.sliders,
            },
        };
    }
    return {
        notFound: true,
    };
}

export default HomePage;
