import Link from "next/link";
import styled from "styled-components";

import { useLoctool } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { underlineLeft } from "@components/Layout";
import { Typography } from "@components/Typography";
import { bp } from "@styles/theme";
import { Path } from "@utils/Path";
import { HtmlUtils } from "@utils/HtmlUtils";

interface OfferCardProps {
    headline: string | null;
    title: string | null;
    subtitle: React.ReactNode | null;
    smallImage: string;
    image: string;
    link?: { url: string; label: string } | null;
    slug?: string;
    buttonHidden?: boolean;
    imgOrientationRight?: boolean;
    className?: string;
    isChristmas?: boolean;
}

export const OfferCard = ({
    headline,
    title,
    subtitle,
    smallImage,
    image,
    link,
    slug,
    buttonHidden,
    imgOrientationRight,
    className,
    isChristmas,
}: OfferCardProps) => {
    const Intl = useLoctool();

    return (
        <StyledOfferCard className={className} data-ce-measure-widget={HtmlUtils.removeTags(title)}>
            <Image $orientationRight={imgOrientationRight}>
                <picture>
                    <source media="(max-width: 767.98px)" srcSet={smallImage} />
                    <source media="(min-width: 768px)" srcSet={image} />
                    <img src={image} alt={HtmlUtils.removeTags(title) || "slider-image"} />
                </picture>
            </Image>
            <Content>
                <ContentWrapper $orientationRight={imgOrientationRight}>
                    {headline && <Headline>{headline}</Headline>}
                    {title && (
                        <Title>
                            <span dangerouslySetInnerHTML={{ __html: HtmlUtils.sanitize(title) }} />
                        </Title>
                    )}
                    {subtitle && (
                        <Lead>
                            {typeof subtitle === "string" ? (
                                <span dangerouslySetInnerHTML={{ __html: HtmlUtils.sanitizeWithLinkSecurity(subtitle) }} />
                            ) : (
                                { subtitle }
                            )}
                        </Lead>
                    )}
                    {link ? (
                        <Link href={link.url}>
                            <StyledLink as="a">{link.label}</StyledLink>
                        </Link>
                    ) : (
                        <>
                            {isChristmas ? (
                                <>
                                    {!buttonHidden && (
                                        <Link href={Path.christmasOffer}>
                                            <StyledLink as="a">{Intl.formatMessage({ id: "pages.discountedOffer.button" })}</StyledLink>
                                        </Link>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!buttonHidden && (
                                        <Link href={`${Path.discountedOffers}/${slug}`}>
                                            <StyledLink as="a">{Intl.formatMessage({ id: "pages.discountedOffer.button" })}</StyledLink>
                                        </Link>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </ContentWrapper>
            </Content>

            {link ? (
                <Link href={link.url}>
                    <OfferCardLink>
                        <span className="show-for-sr">{link.label}</span>
                    </OfferCardLink>
                </Link>
            ) : (
                <>
                    {isChristmas ? (
                        <>
                            {!buttonHidden && (
                                <Link href={Path.christmasOffer}>
                                    <OfferCardLink>
                                        <span className="show-for-sr">{Intl.formatMessage({ id: "pages.discountedOffer.button" })}</span>
                                    </OfferCardLink>
                                </Link>
                            )}
                        </>
                    ) : (
                        <>
                            {!buttonHidden && (
                                <Link href={`${Path.discountedOffers}/${slug}`}>
                                    <OfferCardLink>
                                        <span className="show-for-sr">{Intl.formatMessage({ id: "pages.discountedOffer.button" })}</span>
                                    </OfferCardLink>
                                </Link>
                            )}
                        </>
                    )}
                </>
            )}
        </StyledOfferCard>
    );
};

const StyledOfferCard = styled.div`
    position: relative;
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;

    ${bp.medium} {
        aspect-ratio: 1920 / 694;
        background-color: transparent;
    }

    ${bp.medium} {
        padding-bottom: 0;
    }
`;

const Image = styled.div<{ $orientationRight: boolean | undefined }>`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: ${props => (props.$orientationRight ? "right center" : "left center")};

        ${props =>
            props.$orientationRight === undefined &&
            `
            object-position: right center;
        `}
    }

    ${bp.medium} {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: 0;
        z-index: -1;
    }
`;

const Content = styled.div`
    position: relative;
    width: 100%;
    max-width: 1440px;
    padding: 15px 16px 20px;
    margin: 0 auto;
    text-align: left;

    ${bp.medium} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    ${bp.large} {
        padding: 80px 50px 120px;
    }
`;

const ContentWrapper = styled.div<{ $orientationRight: boolean | undefined }>`
    ${bp.medium} {
        width: 50%;
    }

    ${bp.large} {
        padding-left: 70px;
    }

    ${props =>
        !props.$orientationRight &&
        props.$orientationRight !== undefined &&
        `
            margin-left: auto;
        `}
`;

const Headline = styled(Typography.UpTitle)`
    margin-bottom: 12px;
    font-size: 25px;
    line-height: 28px;

    ${bp.large} {
        max-width: 520px;
        font-size: 28px;
        line-height: 30px;
    }
`;

const Title = styled(Typography.H2)`
    margin-bottom: 14px;
    font-size: 25px;
    line-height: 35px;
    ${underlineLeft}

    ${bp.large} {
        max-width: 520px;
        margin-bottom: 20px;
        font-size: 45px;
        line-height: 59px;
    }
`;

export const Lead = styled.div`
    ${bp.large} {
        max-width: 520px;
        font-size: 25px;
        line-height: 30px;
    }
`;

const StyledLink = styled(Button.Primary)`
    position: relative;
    margin-top: 10px;
    z-index: 2;

    ${bp.medium} {
        margin-top: 20px;
    }

    ${bp.large} {
        margin-top: 30px;
    }
`;

const OfferCardLink = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;
