import React from "react";

import Link from "next/link";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { bundleGotDigitalProduct, NormalProduct, productIsDigital } from "@api/graphql/typeHelpers";
import { Badge } from "@components/Badge";
import { Button, QuantityButton } from "@components/Button";
import { Card } from "@components/Card/Card";
import { Gift, OriginalPrice, Price, ProductImage, Saving } from "@components/Card/Elements";
import { DigitalImageFrame } from "@components/DigitalImageFrame";
import { Flex } from "@components/Flex";
import { Typography } from "@components/Typography";
import { useCart } from "@hooks/useCart";
import { ApplicationState } from "@redux/reducers";
import { bp } from "@styles/theme";
import { ImageUtils } from "@utils/ImageUtils";
import { getProductPageLinkParams } from "@utils/Path";
import { StringUtils } from "@utils/StringUtils";
import { useLoctool } from "@bigfish/react-loctool";
import { CURRENCY_FORMAT_OPTIONS } from "@utils/Constants";
import { HtmlUtils } from "@utils/HtmlUtils";

interface WideProductCardProps {
    product: NormalProduct;
    onCartQuantityChange: (product: NormalProduct, oldQuantity: number, currentQuantity: number) => void;
}

export const WideProductCard: React.FC<WideProductCardProps> = ({ product, onCartQuantityChange }) => {
    const Intl = useLoctool();
    const items = useSelector((state: ApplicationState) => state.cart.allItems);
    const isItemInCart = items.some(item => item.product.id === product.id);
    const itemQuantity = items.filter(x => x.product.id === product.id).length;
    const { addProduct, removeLastAddedProduct, loading } = useCart();

    const handleAdd = async () => {
        const newQuantity = await addProduct(product.id);
        onCartQuantityChange(product, itemQuantity, newQuantity);
    };

    const handleRemove = async () => {
        const newQuantity = await removeLastAddedProduct(product.id);
        onCartQuantityChange(product, itemQuantity, newQuantity);
    };

    const discountPercent = StringUtils.discountPercent(
        "standard_price" in product ? product.standard_price ?? 0 : 0,
        product.discount_price,
        product.price.unit_price,
    );
    const hasStandardPrice =
        "standard_price" in product &&
        product.standard_price &&
        product.standard_price !== 0 &&
        product.standard_price > (product.discount_price ?? product.price.unit_price);

    const showBadge = product.is_badge_discount;

    return (
        <Link href={getProductPageLinkParams(product)}>
            <StyledWideProductCard className="_ce_measure_widget _ce_measure_link" data-ce-measure-widget={`product-${HtmlUtils.removeTags(product.name)}`}>
                <WideProductCardImage>
                    <UpTitle>{product.text_featured && product.text_featured}</UpTitle>
                    {productIsDigital(product) || bundleGotDigitalProduct(product) ? (
                        <ImageWrapper>
                            <DigitalImageFrame
                                image={ImageUtils.productImageWithFallback(product, { width: 242 })}
                                alternate={HtmlUtils.removeTags(product.name)}
                            />
                            {showBadge && discountPercent > 0 && <WideProductCardBadge text={`-${discountPercent}%`} color="red" />}
                        </ImageWrapper>
                    ) : (
                        <ImageWrapper>
                            <ProductImage image={ImageUtils.productImageWithFallback(product, { width: 242 })} alternate={HtmlUtils.removeTags(product.name)} />
                            {showBadge && discountPercent > 0 && <WideProductCardBadge text={`-${discountPercent}%`} color="red" />}
                        </ImageWrapper>
                    )}
                </WideProductCardImage>
                <WideProductCardBody>
                    {product.text_featured && <UpTitleLarge>{product.text_featured}</UpTitleLarge>}
                    <Title>
                        <div dangerouslySetInnerHTML={{ __html: HtmlUtils.sanitize(product.name) }} />
                    </Title>
                    {product.text_gift && <StyledGift>{product.text_gift}</StyledGift>}
                    <Prices $withGift={product.text_gift ? true : false}>
                        {"standard_price" in product && product.standard_price && hasStandardPrice && (
                            <StyledOriginalPrice>{Intl.formatNumber(product.standard_price, CURRENCY_FORMAT_OPTIONS)}</StyledOriginalPrice>
                        )}
                        <StyledPrice>{Intl.formatNumber(product.discount_price ?? product.price.unit_price, CURRENCY_FORMAT_OPTIONS)}</StyledPrice>
                    </Prices>
                    {!isItemInCart && (
                        <AddToCart
                            disabled={loading}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault();
                                handleAdd();
                            }}
                        >
                            {Intl.formatMessage({ id: "pages.home.wideProductCard.addToCart" })}
                        </AddToCart>
                    )}
                    {isItemInCart && itemQuantity && (
                        <QuantityWrapper $disabled={loading}>
                            <QuantityButton name="productQuantity" max={10} quantity={itemQuantity} onAdd={handleAdd} onRemove={handleRemove} />
                        </QuantityWrapper>
                    )}

                    {discountPercent >= 5 && (
                        <StyledSaving>{Intl.formatMessage({ id: "pages.home.wideProductCard.saving" }, { saving: discountPercent })}</StyledSaving>
                    )}
                </WideProductCardBody>
            </StyledWideProductCard>
        </Link>
    );
};

const StyledWideProductCard = styled(Card)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-top: 45px;
    padding-bottom: 25px;
    margin-top: 20px;
    border: 1px solid ${props => props.theme.color.grayL};
    text-align: center;
    cursor: pointer;

    @media screen and (min-width: 375px) {
        margin: 20px 10px 0;
    }

    ${bp.medium} {
        width: calc(50% - 20px);
        margin: 20px 10px 0;
    }

    ${bp.large} {
        flex-direction: row;
        padding: 40px 40px 50px;
        text-align: left;
    }

    ${bp.xlarge} {
        width: calc(50% - 40px);
        margin: 40px 20px 0;
    }
`;

const WideProductCardImage = styled.div`
    ${bp.large} {
        width: 100%;
        max-width: 250px;
        padding-right: 50px;
        padding-bottom: 50px;
    }

    img {
        max-width: 200px;
    }
`;

const WideProductCardBody = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $justifyContent: "flex-end",
})`
    height: 100%;
`;

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 190px;
    margin: 0 auto;

    ${bp.large} {
        max-width: unset;
        margin: 0;
    }
`;

const WideProductCardBadge = styled(Badge)`
    position: absolute;
    bottom: 0;
    left: -10px;
    z-index: 2;

    ${bp.large} {
        left: -15px;
    }
`;

const UpTitle = styled(Typography.UpTitle)`
    display: -webkit-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 45px;
    font-size: 25px;
    text-align: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${bp.large} {
        display: none;
    }
`;

const UpTitleLarge = styled(Typography.UpTitle)`
    display: none;

    ${bp.large} {
        display: block;
        margin-bottom: 5px;
        font-size: 28px;
        line-height: 45px;
    }
`;

const Title = styled(Typography.H3)`
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 25px;

    ${bp.large} {
        margin-bottom: auto;
        font-size: 20px;
        line-height: 30px;
    }
`;

const StyledGift = styled(Gift)`
    justify-content: center;
    width: 100%;
    margin: 10px 0 20px;
    font-size: 15px;
    line-height: 22px;

    ${bp.large} {
        margin: 32px 0;
    }
`;

const Prices = styled(Flex.Container).attrs({
    $justifyContent: "center",
    $alignItems: "flex-end",
})<{ $withGift: boolean }>`
    margin: auto 0 20px;

    ${bp.large} {
        display: block;
        margin-top: ${props => (props.$withGift ? 32 : 88)}px;
    }
`;

const StyledOriginalPrice = styled(OriginalPrice)`
    margin-right: 20px;
    font-size: 20px;

    ${bp.large} {
        margin-bottom: 10px;
    }
`;

const StyledPrice = styled(Price)`
    font-size: 24px;

    ${bp.large} {
        font-size: 35px;
    }
`;

const AddToCart = styled(Button.Primary)`
    width: fit-content;
    margin: 0 auto;

    ${bp.large} {
        margin: 0;
    }
`;

const StyledSaving = styled(Saving)`
    min-height: 12px;
    height: 12px;
    margin-top: 20px;

    ${bp.large} {
        font-size: 14px;
    }
`;

const QuantityWrapper = styled.div<{ $disabled?: boolean }>`
    width: fit-content;
    margin: 0 auto;

    ${bp.large} {
        margin: 0;
    }

    ${props => props.$disabled === true && "opacity: 0.6; pointer-events: none;"}
`;
