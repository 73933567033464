import { Slider } from "@api/graphql/types";
import { useLoctool } from "@bigfish/react-loctool";
import { OfferCard } from "@components/Card/OfferCard";
import { Carousel, CarouselButtonNext, CarouselButtonPrevious } from "@components/Carousel";
import { Container } from "@components/Container";
import { SVGArrowLeft, SVGArrowRight } from "@components/SVGCollection";
import { bp, hexToRGBA } from "@styles/theme";
import { MediaLibraryHelper } from "@utils/MediaLibraryHelper";
import styled, { css } from "styled-components";

type Props = {
    sliders: Slider[];
};

export const HomePageCarousel = ({ sliders }: Props) => {
    const Intl = useLoctool();
    return (
        <StyledCarousel
            static
            reloadOnUpdate
            options={{
                contain: true,
                wrapAround: true,
                prevNextButtons: false,
                imagesLoaded: true,
                pageDots: sliders.length > 1 ? true : false,
            }}
            className="_ce_measure_column carousel"
            measureWidget="top-slider"
            controls={
                sliders.length > 1 ? (
                    <>
                        <CarouselButtonContainer className="_ce_measure_row" data-ce-measure-widget="top-slider-btn">
                            <StyledCarouselButtonPrevious label="Previous" icon={<SVGArrowLeft />} id="top-slider-prev-btn" />
                            <StyledCarouselButtonNext label="Next" icon={<SVGArrowRight />} id="top-slider-next-btn" />
                        </CarouselButtonContainer>
                    </>
                ) : null
            }
            resizeCells
        >
            {sliders.map(slider => (
                <CarouselCell key={slider.id} className="_ce_measure_widget" data-ce-measure-widget="top-slider">
                    <StyledOfferCard
                        className="carousel_cell_content"
                        headline={slider.headline}
                        title={slider.title}
                        subtitle={slider.subtitle}
                        smallImage={MediaLibraryHelper.getMediaLibraryImageSrc(slider.image_url, { width: 768 })}
                        image={MediaLibraryHelper.getMediaLibraryImageSrc(slider.image_url)}
                        imgOrientationRight
                        link={slider.url ? { url: slider.url, label: Intl.formatMessage({ id: "common.learnMore" }) } : null}
                    />
                </CarouselCell>
            ))}
        </StyledCarousel>
    );
};

const StyledCarousel = styled(Carousel)`
    position: relative;
    max-width: 1900px;
    margin: 0 auto;

    .flickity-page-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 60px;

        .dot {
            width: 15px;
            height: 15px;
            margin: 0 5px;
            border: 2px solid ${props => props.theme.color.white};
            opacity: 0.5;

            &.is-selected {
                width: 20px;
                height: 20px;
                opacity: 1;
            }
        }

        ${bp.medium} {
            justify-content: flex-end;
            padding-right: 16px;
        }

        ${bp.large} {
            width: 100%;
            max-width: 1440px;
            bottom: 40px;
            padding-right: 220px;
        }
    }
`;

const StyledOfferCard = styled(OfferCard)`
    padding-bottom: 60px;

    ${bp.medium} {
        padding-bottom: 20px;
    }

    ${bp.large} {
        padding-bottom: 0;
    }
`;

const CarouselCell = styled.div`
    position: relative;
    width: 100%;
`;

const CarouselButtonContainer = styled(Container)`
    display: none;
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    z-index: 1;
    pointer-events: none;

    ${bp.large} {
        display: flex;
    }
`;

const CarouselButton = css`
    display: grid;
    place-items: center;
    min-width: 60px;
    width: 60px;
    min-height: 60px;
    height: 60px;
    background-color: ${props => props.theme.color.white};
    border-radius: 50%;
    pointer-events: all;
    transition: background-color 300ms;

    svg {
        width: 16px;
        height: 26px;
    }

    &:hover {
        background-color: ${props => hexToRGBA(props.theme.color.grayD, 0.2)};
    }
`;

const StyledCarouselButtonPrevious = styled(CarouselButtonPrevious)`
    ${CarouselButton}
    margin-left: auto;
`;

const StyledCarouselButtonNext = styled(CarouselButtonNext)`
    ${CarouselButton}
    margin-right: 0;
    margin-left: 20px;
`;
